<script lang="ts" setup>
const { data } = useAuthState()
const isStaff = computed(() => data?.value?.is_staff)
const cs = useCounterpartiesStore()
const edit = ref(false)
const {data: counterparty} = useAuthenticatedFetch(() => `/api/v1/shop/counterparties/${cs.counterparty}`, { immediate: true })
const contract = computed(() => cs.contracts.data?.value?.find((c) => c.id === cs.contract)?.name)
watch(counterparty, (c) => {
  cs.term = c?.name
})
</script>

<template>
  <div v-if="!edit" class="">
    <div class="flex items-center gap-2">
      <div>
        <div class="font-medium whitespace-nowrap">{{ counterparty?.name }}</div>
        <div class="text-sm text-surface-500 dark:text-surface-400 whitespace-nowrap">{{ contract }}</div>
      </div>
      <Button icon="pi pi-pencil" rounded size="small" text @click="edit=true"/>
    </div>
  </div>
  <Form v-else class="flex flex-col">
    <div class="flex flex-col gap-2">
      <label class="font-medium" for="name">Смена контрагента</label>
      <Select
        v-model="cs.term"
        :editable="isStaff"
        :loading="unref(cs.filter.status) === 'pending'"
        :options="unref(cs.filter.data) as any[]"
        class="w-full text-left"
        name="name"
        option-label="name"
        overlay-class="max-w-96"
        placeholder="Начните вводить имя или название"
        show-clear
        size="small"
      />
    </div>
    <Select
      v-model="cs.contract"
      :options="unref(cs.contracts.data) as any[]"
      class="mt-2"
      option-label="name"
      option-value="id"
      overlay-class="max-w-96"
      placeholder="Договор"
      size="small"
    />
  </Form>
</template>
