<template>
  <QHeader/>
  <div class="container mx-auto mt-12 px-2 lg:px-4 flex flex-col min-h-screen">
    <NuxtPage/>
  </div>
  <QDialogFeedback
    v-model="showFeedbackForm"
  />
  <QCartGlobal class="hidden lg:block" v-if="!$route.fullPath.startsWith('/cart')"/>
  <QFooter />
  <QBottomNavbar class="lg:!hidden"/>
  <Toast class="size-" group="app"/>
</template>

<script lang="ts" setup>
import { ru } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import { CONF_SYMBOL } from "~/injection/symbols";

setDefaultOptions({ locale: ru })

const conf = reactive({
  contentViewType: 'grid'
})

provide(CONF_SYMBOL, conf)

onMounted(() => {
  if (process.client) {
    const viewType = window.localStorage.getItem('catalogViewType')
    if (viewType && viewType !== '') {
      conf.contentViewType = viewType
    }
  }
})

watch(() => conf.contentViewType, (viewType) => {
  if (process.client && viewType !== '') {
    window.localStorage.setItem('catalogViewType', viewType)
  }
})

const showFeedbackForm = ref(true)
</script>
