<template>
  <header ref="el" class="container mx-auto">
    <Drawer v-model:visible="sidebarDrawerVisible" :pt="{
      header: () => ({
      class: 'px-3'
      }),
      content: () => ({
      class: 'px-0'
      }),
      pcCloseButton: {
        root: () => ({class: 'hidden'})
      },
    }" position="left">
      <template #header>
        <NuxtLink class="relative" to="/">
          <img alt="Logo" src="~/assets/h-light-logo.svg" class="w-2/3">
        </NuxtLink>
      </template>
      <Menu
        :model="[
          {
            label: 'Каталог',
            icon: 'pi pi-th-large',
            url: '/'
          },
          {
            label: 'Мои заказы',
            icon: 'pi pi-list',
            url: '/orders'
          }
        ]"
        class="border-none"
      />
    </Drawer>
    <Toolbar class="border-none">
      <template #start>
        <Button class="lg:hidden" icon="pi pi-bars" text @click="sidebarDrawerVisible = true"/>
        <div class="hidden lg:flex gap-5">
          <NuxtLink to="/">
            <img alt="Logo" src="~/assets/h-light-logo.svg">
          </NuxtLink>
          <NuxtLink to="/">
            <Button
              :severity="catalogLinkActive ? '' : 'secondary'"
              label="Каталог"
              small
              text
            />
          </NuxtLink>
          <NuxtLink v-slot="{isActive}" to="/orders">
            <Button
              :severity="isActive ? '' : 'secondary'"
              label="Мои заказы"
              small
              text
            />
          </NuxtLink>
        </div>
      </template>
      <template #center>
        <QHeaderSearchForm class="lg:w-96"/>
      </template>
      <template #end>
        <div class="hidden lg:flex items-center gap-2">
          <div class="card flex justify-center">
            <Button
              aria-controls="overlay_menu"
              aria-haspopup="true"
              icon="pi pi-bell"
              rounded
              severity="secondary"
              text
              @click="toggleNotificationsPopover"
            />
            <Popover ref="notificationsPopover" class="min-w-96">
              <QNotificationsList :notifications="ns.notifications"/>
            </Popover>
          </div>
          <div class="card flex justify-center">
            <Button
              aria-controls="overlay_menu"
              aria-haspopup="true"
              icon="pi pi-address-book"
              rounded
              severity="secondary"
              text
              @click="toggleCounterpartyPopover"
            />
            <Popover ref="counterpartyPopover" class="">
              <QCounterpartyChangePopover class="min-w-80"/>
            </Popover>
          </div>
          <NuxtLink to="/cart" class="relative">
            <Button
              icon="pi pi-shopping-bag"
              rounded
              severity="secondary"
              text
            />
          </NuxtLink>
          <div class="card flex justify-center">
            <Button
              aria-controls="overlay_menu"
              aria-haspopup="true"
              icon="pi pi-user"
              rounded
              severity="secondary"
              text
              @click="toggleAccountMenu"
            />
            <Menu
              ref="accountMenu"
              :model="[
                {
                  icon: 'pi pi-user',
                  label: 'Личный кабинет',
                  command: () => $router.push('/account'),
                },
                {
                  icon: 'pi pi-sign-out',
                  label: 'Выход',
                  command: onSignOutClick,
                }
              ]"
              popup
            />
          </div>
        </div>
      </template>
    </Toolbar>
  </header>
</template>

<script lang="ts" setup>
const ns = useNotificationsStore()
const { el } = useElementHeightToCssVariable('--q-header-height')
const route = useRoute()
const isNotificationsVisible = ref(false)
ns.fetch()

watch(isNotificationsVisible, (flag, oldFlag) => {
  if (flag === false && oldFlag === true) {
    ns.markAsRead()
    ns.fetch()
  }
}, {
  immediate: false
})
const cartStore = useCartStore()
const sidebarDrawerVisible = ref(false)
const { signOut } = useAuth()
const counterpartyPopover = ref(false)
const notificationsPopover = ref(false)
const accountMenu = ref(false)
const toggleCounterpartyPopover = (event) => counterpartyPopover.value.toggle(event)
const toggleNotificationsPopover = (event) => notificationsPopover.value.toggle(event)
const toggleAccountMenu = (event) => accountMenu.value.toggle(event)

const catalogLinkActive = computed(() => {
  if (route.path.startsWith('/category/') || route.path.startsWith('/product/')) {
    return true
  }
})

const refreshToken = useCookie('auth.refresh-token')
const onSignOutClick = () => {
  refreshToken.value = null
  localStorage.removeItem('counterparties')
  signOut({ callbackUrl: '/auth/login' })
}

if (!cartStore.items.length) {
  cartStore.fetch()
}
</script>
