<template>
  <IconField>
    <InputIcon>
      <i class="pi pi-search"/>
    </InputIcon>
    <InputText
      v-model="itemsStore.item_name"
      :placeholder="$t('Search')"
      class="w-full"
      size="small"
      @input="fetchDebounce"
    />
  </IconField>
</template>

<script lang="ts" setup>
import { debounce } from 'lodash-es'

const itemsStore = useItemsStore()
const fetchDebounce = debounce(itemsStore.fetch, 400)
</script>
