<script lang="ts" setup>
import type { MenuItem } from "primevue/menuitem";

const router = useRouter()
const { signOut } = useAuth()
const ns = useNotificationsStore()
const { reset } = useCounterpartyCookie()
const refreshToken = useCookie('auth.refresh-token')

const isNotificationsVisible = ref(false)

const accountDrawer = ref(false)
const counterpartyDrawer = ref(false)
const notificationsPopoverRef = ref(false)
const toggleAccountDrawer = () => accountDrawer.value = !accountDrawer.value
const toggleCounterpartyDrawer = () => counterpartyDrawer.value = !counterpartyDrawer.value
const toggleNotificationsPopover = (event) => notificationsPopoverRef.value.toggle(event)

const onSignOutClick = () => {
  reset()
  refreshToken.value = null
  signOut({ callbackUrl: '/auth/login' })
}

ns.fetch()
watch(isNotificationsVisible, (flag, oldFlag) => {
  if (flag === false && oldFlag === true) {
    ns.markAsRead()
    ns.fetch()
  }
}, {
  immediate: false
})
const cs = useCartStore()

const navbarItems = ref<MenuItem[]>([
  {
    label: 'Личный кабинет',
    icon: 'pi pi-user',
    command: toggleAccountDrawer
  },
  {
    label: 'Контрагент',
    icon: 'pi pi-address-book',
    command: toggleCounterpartyDrawer
  },
  {
    label: 'Уведомления',
    icon: 'pi pi-bell',
    command: toggleNotificationsPopover
  },
  {
    label: 'Корзина',
    icon: 'pi pi-shopping-bag',
    command: () => router.push('/cart'),
    badge: computed(() => cs.items.length),
  }
]);

const accountDrawerMenu = [
  {
    icon: 'pi pi-user',
    label: 'Личный кабинет',
    command: () => {
      router.push('/account')
      toggleAccountDrawer()
    },
  },
  {
    icon: 'pi pi-sign-out',
    label: 'Выход',
    command: onSignOutClick
  }
]

</script>

<template>
  <div>
    <Drawer
      v-model:visible="counterpartyDrawer" :pt="{
      header: () => ({
      class: 'hidden',
      }),
      content: () => ({
      class: 'p-4'
    })}"
      :show-close-icon="false" position="bottom"
      style="height: auto">
      <QCounterpartyChangePopover/>
    </Drawer>
    <Drawer
      v-model:visible="accountDrawer"
      :pt="{
      header: () => ({
      class: 'hidden',
      }),
      content: () => ({
      class: 'p-2'
    })}"
      :show-close-icon="false"
      position="bottom" style="height: auto">
      <Menu :model="accountDrawerMenu" class="border-none"/>
    </Drawer>

    <Popover ref="notificationsPopoverRef" class="min-w-96">
      <QNotificationsList :notifications="ns.notifications"/>
    </Popover>

    <div class="container mx-auto px-2 w-full">
      <div class="flex fixed bottom-0 left-0 justify-between w-full bg-white p-2">
        <div v-for="item in navbarItems" class="flex items-center justify-center size-12 rounded-xl relative">
          <div v-if="item.badge" class="z-10 size-4 absolute right-1 top-1 flex items-center justify-center rounded-full bg-pink-500/40 text-pink-500 text-xs">{{ item.badge }}</div>
          <Button :icon="item.icon" text @click="item?.command"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
